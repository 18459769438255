<template>
  <div class="e-product e-product--prod">
    <div class="e-product__image">
      <img :src="require(`@/assets/images/${product.img}`)" :alt="product.name">
    </div>
    <!-- / e-product__image -->

    <div class="e-product__bottom">
      <input class="e-product__checkbox" type="checkbox" @change="saveProduct($event)" :checked="checked">
      <div class="e-product__checked"></div>
      <p class="e-product__name">{{ product.name }}</p>
    </div>
    <!-- / e-product__bottom -->

    <transition name="fade">
      <div class="e-product__options" :class="{ 'open': isOpen }" v-if="checked">
        <div class="e-product__option">
          <Select :product="product" @toggleOpen="toggleOpen()" />
        </div>
        <!-- / e-product__option -->
        
        <div class="e-product__option" v-if="! isOpen">
          <div class="e-range">
            <p class="e-range__title">
              Ilość
            </p>
            <div class="e-range__items">
              <span class="e-range__btn" @click="decreaseAmount()">&minus;</span>
              <input type="range" min="1" max="30" value="1" v-model="amount" class="e-range__slider">
              <span class="e-range__btn" @click="increaseAmount()">&plus;</span>
            </div>
            <!-- / e-range__items -->

            <p class="e-range__value">
              {{ amount + ' ' + amountString }}
            </p>
          </div>
          <!-- / e-range -->

        </div>
        <!-- / e-product__option -->

      </div>
      <!-- / e-product__options -->

    </transition>

  </div>
  <!-- / e-product -->
</template>

<script>
import Select from "@/components/Select.vue";

export default {
  name: "Product",
  props: {
    product: {
      type: Object
    }
  },
  components: {
    Select
  },
  data() {
    return {
      selected: false,
      isOpen: false,
    }
  },
  methods: {
    toggleOpen() {
      this.isOpen = ! this.isOpen;
    },
    increaseAmount() {
      if (this.amount < 30) {
        this.amount++;
      }
    },
    decreaseAmount() {
      if (this.amount > 1) {
        this.amount--;
      }
    },
    saveProduct(event) {
      this.$store.commit('saveProduct', { id: this.product.id, value: event.target.checked });
    }
  },
  computed: {
    selectedProducts() {
      return this.$store.state.selectedProducts;
    },
    selectedProduct() {
      return this.$store.state.selectedProducts[this.product.id];
    },
    amountString() {
      return this.amount > 1 ? (this.amount >= 5 ? 'sztuk' : 'sztuki') : 'sztuka';
    },
    checked() {
      return this.product.id in this.selectedProducts;
    },
    amount: {
      get() {
        return this.selectedProduct.amount;
      },
      set(value) {
        this.$store.commit('updateValue', { id: this.product.id, value });
      }
    }
  },
  watch: {
  }
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/e-product.scss';

.e-range {

  &__title {
    @extend %product-name;
    font-weight: 700;
    color: $color-text;
    margin-bottom: 5px;
  }

  &__items {
    @include flex(space-between, center, nowrap);
  }

  &__btn {
    font-family: $font-main;
    font-size: 21px;
    font-weight: 600;
    color: $color-text;
    cursor: pointer;
    line-height: 1;
    display: inline-block;
    user-select: none;
  }

  &__slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 6px;
    background: $color-bg-alt;
    outline: none;
    border-radius: 3px;
    margin: 0 14px;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 10px;
      height: 10px;
      background: $color-primary;
      cursor: pointer;
      border-radius: 50%;
    }

    &::-moz-range-thumb {
      width: 10px;
      height: 10px;
      background: $color-primary;
      cursor: pointer;
      border-radius: 50%;
    }
  }

  &__value {
    @extend %product-name;
    font-weight: 500;
    color: $color-text;
    margin-top: 7px;
  }
}

.fade-enter-active, .fade-leave-active {
  @include transition();
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(-10px);
}
</style>

