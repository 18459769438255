<template>
  <div class="e-select" v-click-outside="() => open = false">
    <div class="e-select__top" @click="toggleOpen()">
      <div class="e-select__wrap">
        <span class="e-select__title">Materiał</span>
        <img class="e-select__info" src="@/assets/images/info.svg" alt="icon" @click.stop="showPopup()">
      </div>
      <img class="e-select__arrow" src="@/assets/images/down-chevron.svg" alt="icon" :class="{ open }">
    </div>
    <!-- / e-select__top -->

    <div class="e-select__bottom">
      <div class="e-select__selected" v-if="! open">
        {{ selectedProduct.material }}
      </div>
      <!-- / e-select__selected -->

      <div class="e-select__options" v-if="open">
        <p class="e-select__option" v-for="(option, index) in product.options.material" :key="index" :class="option == selectedProduct.material ? 'active' : ''" @click="updateOption(option)">{{ option }}</p>
      </div>
      <!-- / e-select__options -->

    </div>
    <!-- / e-select__bottom -->

  </div>
  <!-- / e-select -->

</template>

<script>
// @ is an alias to /src
import ClickOutside from 'vue-click-outside'

export default {
  name: "Select",
  directives: {
    ClickOutside
  },
  props: {
    product: {
      type: Object
    }
  },
  data() {
    return {
      open: false,
    }
  },
  components: {
  },
  methods: {
    toggleOpen() {
      this.open = !this.open;
    },
    updateOption(option) {
      this.toggleOpen();
      this.$store.commit('updateOption', { id: this.product.id, option });
    },
    showPopup() {
      this.$root.$emit('showPopup', this.product.id);
    }
  },
  computed: {
    selectedProduct() {
      return this.$store.state.selectedProducts[this.product.id];
    }
  },
  watch: {
    open() {
      this.$emit('toggleOpen');
    }
  }
};
</script>

<style scoped lang="scss">
.e-select {

  &__top {
    @include flex(space-between, center, nowrap);
    padding-bottom: 14px;
    border-bottom: 1px solid $color-line;
    cursor: pointer;
    user-select: none;
  }

  &__title {
    @extend %product-name;
    font-weight: 700;
    color: $color-text;
  }
  
  &__bottom {
    @extend %product-name;
    font-weight: 500;
    color: $color-text;
    padding-top: 15px;
    position: relative;
  }

  &__arrow {
    @include transition();
    width: 14px;
    height: auto;

    &.open {
      transform: rotate(180deg);
    }
  }

  &__wrap {
    @include flex(flex-start, center, nowrap);
  }

  &__info {
    height: 19px;
    width: 19px;
    margin-left: 11px;
  }

  &__options {
    max-height: 121px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      background: $color-scroll;
    }
    &::-webkit-scrollbar-thumb {
      background: $color-primary;
      border-radius: 2px;
    }
  }

  &__option {
    margin-bottom: 17px;
    cursor: pointer;

    &:last-of-type {
      margin-bottom: 0;
    }

    &.active {}
  }
}
</style>

