<template>
  <main class="home-page">
    <Heading :title="'Przejdź do sklepu lub wyślij zapytanie'" />
    <section class="step">
      <div class="step__buttons">
        <div class="step__buttons-container">
          <Button url="https://sklep.dobroplast.pl/" title="Kup okna i drzwi teraz" classes="e-button--primary" />
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.392 25.657"><defs></defs><g id="1550af2b-8fdd-4372-b0de-b8f2336c1ecf" data-name="Layer 2"><g id="10e5df07-7f8a-4e1f-aad9-4cc0cadbbfbf" data-name="Layer 1"><path class="e0b91958-6862-4bbd-a551-ebb281af2644" d="M29.022,1.949a.5.5,0,0,0-.613.351L27.97,3.913H5.243l.492-2.477a.5.5,0,0,0,0-.2A1.571,1.571,0,0,0,4.205,0H.5a.5.5,0,0,0,0,1H4.205a.567.567,0,0,1,.526.359L4.144,4.315v.007a.494.494,0,0,0,0,.206l0,.005v0L6.707,15.349l-.671,1.984A1.377,1.377,0,0,0,7.39,18.987H25.233a.5.5,0,0,0,0-1H7.39a.38.38,0,0,1-.3-.141A.368.368,0,0,1,7,17.6l.586-1.736L24.737,13.7a2.494,2.494,0,0,0,2.1-1.824l2.536-9.316A.5.5,0,0,0,29.022,1.949Zm-3.149,9.667a1.494,1.494,0,0,1-1.261,1.094l-17,2.137L5.266,4.913H27.7Z"/><path class="e0b91958-6862-4bbd-a551-ebb281af2644" d="M8.436,20.884a2.387,2.387,0,1,0,2.393,2.387A2.394,2.394,0,0,0,8.436,20.884Zm0,3.773a1.387,1.387,0,1,1,1.393-1.386A1.393,1.393,0,0,1,8.436,24.657Z"/><path class="e0b91958-6862-4bbd-a551-ebb281af2644" d="M23.339,20.884a2.387,2.387,0,1,0,2.4,2.387A2.394,2.394,0,0,0,23.339,20.884Zm0,3.773a1.387,1.387,0,1,1,1.4-1.386A1.392,1.392,0,0,1,23.339,24.657Z"/></g></g></svg>
        </div>
        <div class="step__buttons-container">
          <Button path="step1" title="Potrzebuję konsultacji przed zakupem i chcę wypełnić formularz" classes="e-button--primary" />
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.673 29.531"><defs></defs><g id="bd6b31c3-3298-41dc-8aaa-566b18c5532c" data-name="Layer 2"><g id="dd3fa972-25f4-4a8a-a276-a931d1ae4bde" data-name="Layer 1"><path class="9afc2a82-68c6-49b4-a917-e4ff57fc571d" d="M29.289,4.467,25.19.37a1.341,1.341,0,0,0-1.851,0L8.073,15.636c-.118.118-.239.24-2.931,6.983l-.129.322a1.308,1.308,0,0,0,1.7,1.706l.17-.067,1.028-.411.013-.006c5.865-2.348,5.994-2.474,6.094-2.577L29.289,6.318h0a1.3,1.3,0,0,0,.383-.925A1.318,1.318,0,0,0,29.289,4.467ZM11.408,19.679s.006.01.01.014.01.006.014.01l1.382,1.381c-.65.282-2.036.858-4.962,2.031L6.544,21.806c1.183-2.951,1.759-4.338,2.025-4.967ZM6.516,23.65l-.173.069a.31.31,0,0,1-.405-.4l.132-.329.07-.174.7.7ZM28.581,5.612,13.675,20.531l-1.194-1.194L25.784,6.05a.5.5,0,1,0-.707-.708L11.774,18.63l-2.64-2.64L24.048,1.076a.3.3,0,0,1,.217-.089.307.307,0,0,1,.218.09l4.095,4.094a.318.318,0,0,1,.095.222A.312.312,0,0,1,28.581,5.612Z"/><path class="9afc2a82-68c6-49b4-a917-e4ff57fc571d" d="M22.444,15.73a.5.5,0,0,0-.5.5V27.2c0,1.225-.447,1.328-.906,1.328H2.329A1.245,1.245,0,0,1,1,27.2V8.619c0-.335,0-1.033,1.456-1.033H13.3a.5.5,0,0,0,0-1H2.456C.319,6.586,0,7.86,0,8.619V27.2a2.252,2.252,0,0,0,2.329,2.328H21.038c.869,0,1.906-.4,1.906-2.328V16.23A.5.5,0,0,0,22.444,15.73Z"/></g></g></svg>
        </div>
      </div>
      <img src="@/assets/images/windows.png" class="step__home-img" alt="">
    </section>
  </main>
</template>

<script>
// @ is an alias to /src
import Heading from "@/components/Heading.vue";
import Button from "@/components/Button.vue";

export default {
  name: "Home",
  components: {
    Heading, Button
  },
  mounted() {
    this.$cookiebot.consentBanner();
  }
};
</script>

<style scoped lang="scss">
.fade-enter-active, .fade-leave-active {
  @include transition();
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.step {
  
  &__buttons {
    @include flex(center, center, wrap);
    flex-direction: column;
    height: 320px;
    
    @media #{$md} {
      height: 470px;
    }
    @media #{$xl} {
      flex-direction: row;
    }

    &-container {
      position: relative;
      margin-bottom: 20px;
      width: 100%;

      @media #{$xl} {
      width: auto;
      margin-bottom: 0;
      margin-right: 20px;

      &:nth-last-of-type(1) {
        margin-right: 0;
      }
    }

      &:hover {
        svg {
          fill: $color-primary;
        }
      }

      svg {
        @include transition();
        pointer-events: none;
        height: 50%;
        width: auto;
        object-fit: contain;
        position: absolute;
        top: 50%;
        right: 28px;
        transform: translateY(-50%);
        fill: $color-bg;

        @media #{$sm} {
          height: 72%;
        }
      }
    }
    
    .e-button {
      @include flex(flex-start, center, wrap);
      margin-bottom: 25px;
      padding-right: 95px;
      padding-left: 28px;
      line-height: 1.3;
      font-size: 20px;
      font-weight: 700;
      text-align: left;
      height: 108px;
      width: 100%;
      position: relative;
      
      @media (max-width: #{$max-xs}) {
        font-size: 11px;
      }
      @media #{$sm} {
          padding-right: 130px;
      }
      
      @media #{$xl} {
        width: 402px;  
      }
      
      &:last-of-type,
      &:nth-of-type(1) {
        margin-bottom: 0;

        &::after {
          content: '';
          display: block;
          height: 100%;
          position: absolute;
          top: 50%;
          right: 0;
        }
      }
    }
  }

  &__home-img {
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: translate(46%,45%);
    width: 70%;

    @media #{$xl} {
      bottom: 0;
      right: 0;
      transform: translate(-16%,45%);
      width: auto;
    }
  }
}
</style>