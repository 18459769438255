<template>
  <main class="step-page-1">
    <Heading :title="'Wybór produktu'" />
    <section class="step1 step">
      <transition name="fade">
        <Popup v-if="popup" :product="popup" @closePopup="popup = null" />
      </transition>
      <div class="step__products">
        <Product data-aos="fade-in" :data-aos-delay="index*250" v-for="(product, index) in products" :key="product.id" :product="product" />
      </div>
      <Bottom :justify="'end'">
        <Button :path="'step2'" :title="'Dalej'" :classes="btnClass" />
      </Bottom>
    </section>
  </main>
</template>

<script>
// @ is an alias to /src
import Product from "@/components/Product.vue";
import Bottom from "@/components/Bottom.vue";
import Button from "@/components/Button.vue";
import Popup from "@/components/Popup.vue";
import Heading from "@/components/Heading.vue";

export default {
  name: "Step1",
  components: {
    Product,
    Bottom,
    Button,
    Popup,
    Heading
  },
  data() {
    return {
      popup: null,
    }
  },
  mounted() {
    this.$root.$on('showPopup', value => {
      this.popup = value;
    });
  },
  computed: {
    products() {
      return this.$store.state.products;
    },
    btnClass() {
      return Object.keys(this.$store.state.selectedProducts).length ? 'e-button--primary' : 'e-button--disabled';
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.checkRoute(to, from)) {
      next();
    }

    if (! Object.keys(this.$store.state.selectedProducts).length) {
      return;
    }

    next();
  }
};
</script>

<style scoped lang="scss">
.step1 {}

.fade-enter-active, .fade-leave-active {
  @include transition();
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>

