<template>
  <div class="m-popup m-popup--flex">
    <div class="m-popup__close" @click="closePopup()">X</div>
    <p class="m-popup__title">
      Dostępne materiały
    </p>
    <div class="m-popup__items" v-if="infos">
      <div class="e-item" v-for="(info, index) in infos" :key="index">
        <div class="e-item__image">
          <img :src="require(`@/assets/images/${info.image}`)" :alt="info.title">
        </div>
        <!-- e-item__image -->

        <div class="e-item__content">
          <p class="e-item__title">{{ info.title }}</p>
          <p class="e-item__desc">{{ info.desc }}</p>
        </div>
        <!-- / e-item__content -->

      </div>
      <!-- / e-item -->

    </div>
    <!-- / m-popup__items -->

  </div>
  <!-- / m-popup -->

</template>

<script>
export default {
  name: "Date",
  props: {
    product: {
      type: Number
    }
  },
  methods: {
    closePopup() {
      this.$emit('closePopup');
    }
  },
  computed: {
    products() {
      return this.$store.state.products;
    },
    infos() {
      return this.products.find(item => item.id == this.product).info;
    }
  }
};
</script>

<style scoped lang="scss">
.m-popup {
  z-index: 10;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $color-white;
  padding: 40px 20px;

  @media #{$md} {
    padding: 63px 54px;
  }

  &__close {
    font-family: $font-main;
    font-size: 22px;
    color: $color-primary;
    font-weight: 700;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    line-height: 1;

    @media #{$md} {
      font-size: 27px;
      right: 30px;
      top: 30px;
    }
  }

  &__title {
    @extend %popup-title;
    color: $color-primary;
    margin-bottom: 30px;

    @media #{$lg} {
      margin-bottom: 60px;
    }
  }

  &__items {
    @include flex(space-between, flex-start, wrap);
  }

  &--flex {
    @media #{$xl} {
      @include flex(flex-start, center, wrap);
    }

    .m-popup__title {
      width: 100%;
      align-self: flex-start;
    }
  }
}

.e-item {
  @include flex(flex-start, flex-start, nowrap);
  margin-bottom: 40px;

  @media #{$md} {
    margin-bottom: 60px;
  }
  @media #{$xl} {
    flex: 45% 0 0;
    margin-bottom: 93px;
  }

  &__image {
    background-color: $color-scroll;
    flex: 0 0 110px;
    position: relative;

    @media #{$sm} {
      flex: 0 0 127px;
    }

    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    color: $color-text;
    padding-left: 15px;

    @media #{$sm} {
      padding-left: 30px;
    }
  }

  &__title {
    @extend %popup-subtitle;
    margin-bottom: 5px;
  }

  &__desc {
    @extend %product-name;
    line-height: 1.86;
  }
}
</style>

